import { useState } from "react";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MailIcon from '@mui/icons-material/Mail';
import EventIcon from '@mui/icons-material/Event';
import FadeIn from "react-fade-in"; //https://www.npmjs.com/package/react-fade-in
import Divider from '@mui/material/Divider';
import Typewriter from 'typewriter-effect';
import Box from '@mui/material/Box';
import CalendarDialog from "./CalendarDialog";

const HeaderText = () => {
    const [openCalendarDialog, setOpenCalendarDialog] = useState(false);
    return (
        <>
            <div style={{ 'position': 'sticky', 'zIndex': '10' }}>
                <FadeIn>
                    <Typography variant="h2" color="primary.main" align="left">
                        Ongevalrisico <img
                            //className='grayscale'
                            align='center'
                            src='img/logo_ongevalrisico.png'
                            alt='ongevalrisico'
                            loading="lazy"
                            height="50px"
                            width="50px"
                        />
                    </Typography>
                    <Typography variant="h4" color="text.primary" align="left">
                        {"Welke wegen zijn gevaarlijk en waarom"}
                    </Typography>
                    <Divider style={{ 'marginTop': '1rem', 'marginBottom': '1rem' }} />
                    <ButtonGroup size="large" aria-label="primary button group">
                        <Button variant="contained" startIcon={<ExitToAppIcon />} href="https://roadsafety.dokdata.nl" target="_blank">Login</Button>
                        <Button variant="outlined" startIcon={<ExitToAppIcon />} href="https://verkeersveiligheid.dokdata.nl" target="_blank">Login Verkeersveiligheidsmeter Utrecht</Button>
                    </ButtonGroup>
                    <div className='fillerSmall' />
                    <ButtonGroup size="large" aria-label="primary button group">
                        <Button variant="contained" color="warning" startIcon={<EventIcon />} onClick={() => setOpenCalendarDialog(true)}>Plan een demosessie</Button>
                    </ButtonGroup>
                    <div className='fillerSmall' />
                    <ButtonGroup size="large" aria-label="primary button group">
                        <Button variant="contained" startIcon={<MailIcon />} href="mailto:support@dokdata.nl">support@dokdata.nl</Button>
                    </ButtonGroup>


                </FadeIn>
                <div className='fillerSmall' />
                <ul className='checklist'>
                    <FadeIn delay='300'><li className='checklist'><Typography variant="body1" color="primary" align="left"><Box component="span" fontWeight='fontWeightMedium'>Landelijk beschikbaar</Box></Typography></li></FadeIn>
                    <FadeIn delay='600'><li className='checklist'><Typography variant="body1" align="left">Direct toepaspaar</Typography></li></FadeIn>
                    <FadeIn delay='900'><li className='checklist'>
                        <Typography variant="body1" align="left" style={{ float: 'left' }}>Voor&nbsp;</Typography>
                        <Typewriter style={{ float: 'left' }}
                            options={{
                                strings: ['verkeerskundigen', 'wegontwerpers', 'wegbeheerders'],
                                delay: 25,
                                autoStart: true,
                                loop: true,
                            }}
                        />
                    </li></FadeIn>
                    {/* <FadeIn delay='1200'><li className='checklist'><Typography variant="body1" align="left">Open data waar mogelijk</Typography></li></FadeIn> */}
                    {/* <FadeIn delay='1500'><li className='checklist'><Typography variant="body1" align="left">Focus op datakwaliteit</Typography></li></FadeIn> */}
                    {/* <FadeIn delay='1800'><li className='checklist'><Typography variant="body1" align="left">Schappelijke kosten</Typography></li></FadeIn> */}
                </ul >
                {/* <Typography variant="body1" align="left">
                <Box component="div" fontWeight='fontWeightMedium'>Inzicht in risicovolle wegen en kruispunten</Box>
                    <Box component="div" fontWeight='fontWeightMedium'>Direct toepaspaar voor prioritering en uitvoeringsagenda's</Box>
                    <Box component="div" fontWeight='fontWeightMedium'>Landelijk beschikbaar voor alle wegbeheerders in Nederland</Box>
                </Typography> */}
            </div >
            <CalendarDialog
                open={openCalendarDialog}
                handleClose={() => setOpenCalendarDialog(false)}
                iframeSrc='https://koalendar.com/e/demo-ongevalrisiconl?embed=true'
            />
        </>
    )
}

export default HeaderText
