import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';

const About = () => {
    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">
                {"Over"}
            </Typography>
            <div className='fillerSmall' />
            <Typography variant="body1" color="text.primary" align="left">
                Ontwikkelaar <a href="https://dokdata.nl" target="_blank">DOK data</a> maakt met een kopgroep wegbeheerders de slag van maatwerkprojecten naar (landelijke) standaardproducten geschikt voor alle wegbeheerders in Nederland. Doel is relevante verkeersveiligheidsproducten tegen schappelijke kosten aan te bieden. DOK data werkt hierin samen met een kopgroep van wegbeheerders en diverse private partners waaronder <a href="https://www.arcadis.com/nl-nl" target="_blank">Arcadis</a> voor verkeerskundige expertise, <a href="https://cyclomedia.com/nl" target="_blank">Cyclomedia</a> voor fietsdata en <a href="https://ellipsis-drive.com" target="_blank">Ellipsis Drive</a> voor stabiele GIS data opslag.
                {/* De kopgroep bestaat onder andere uit de <a href="https://rotterdam.nl" target="_blank">gemeente Rotterdam</a>, <a href="https://provincie-utrecht.nl" target="_blank">provincie Utrecht</a>, <a href="https://denhaag.nl" target="_blank">gemeente Den Haag</a>, <a href="https://www.gelderland.nl" target="_blank">provincie Gelderland</a>, <a href="https://fryslan.frl" target="_blank">provincie Fryslan</a> en de <a href="https://provincie.drenthe.nl" target="_blank">provincie Drenthe</a>. */}
            </Typography>
            <div className='fillerSmall' />
            <Typography variant="body1" color="text.primary" align="left">
                Een voorbeeld is de <a href="https://verkeersveiligheidsmeter.nl" target="_blank">Verkeersveiligheidsmeter</a> ontwikkeld als maatwerkproject voor de <a href="https://provincie-utrecht.nl" target="_blank">provincie Utrecht</a> en onderliggende wegbeheerders. Diverse risico-indicatoren en appfunctionaliteiten zijn, met goedkeuring van Utrecht, landelijk beschikbaar gesteld voor alle wegbeheerders via ongevalrisico.nl.
            </Typography>
            <div className='fillerSmall' />
            <Grid container justifyContent="center" alignItems="center" spacing={4}>
                {/* <Grid item xs={12}>
                    <div className='fillerSmall' />
                    <Typography variant="body1" color="text.primary" align="center">
                        Kopgroep wegbeheerders
                    </Typography>
                </Grid>
                <div className='fillerSmall' />
                {clientData.map((item) => (
                    <Grid item xs={4} sm={2} lg={2} key={item.title}>
                        <div align='center'>
                            <a href={item.link} target="_blank">
                                <img
                                    className='grayscale'
                                    align='center'
                                    src={`${item.img}`}
                                    alt={item.title}
                                    loading="lazy"
                                    height="80px"
                                    width="100px"
                                />
                            </a>
                        </div>
                    </Grid>
                ))} */}
                {/* <Grid item xs={12}>
                    <div className='fillerSmall' />
                    <Typography variant="body1" color="text.primary" align="center">
                        Partners
                    </Typography>
                </Grid> */}
                {companyData.map((item) => (
                    <Grid item xs={4} sm={2} lg={2} key={item.title}>
                        <div align='center'>
                            <a href={item.link} target="_blank">
                                <img
                                    className='grayscale'
                                    align='center'
                                    src={`${item.img}`}
                                    alt={item.title}
                                    loading="lazy"
                                    height="80px"
                                    width="100px"
                                />
                            </a>
                        </div>
                    </Grid>
                ))}
            </Grid>

            <Grid container justifyContent="center" alignItems="center" spacing={4}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: '1rem' }}>
                        <List dense={true}>

                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src="img/sipke.png" width="50px" height="50px" alt="Sipke van der Meulen" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary='"Met de site ongevalsrisico.nl ontwikkelen we (markt en regisseurs verkeersveiligheid) in ééndrachtige samenwerking een mooi product, goed bruikbaar voor wegbeheerders en het maatschappelijk middenveld. De kracht van dit initiatief zit in de bundeling van kennis, financiën èn data! Fryslân werkt daar graag aan mee."'
                                    secondary='Sipke van der Meulen, secretary of the Regional Traffic and Transport Board (OVVF) provincie Fryslan'
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>

                {/* <Grid item xs={12}>
                    <Paper sx={{ padding: '1rem' }}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src="img/remco.png" width="50px" height="50px" alt="Remco Smit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary='"Met het landelijke verkeersveiligheidsmodel zet DOK data met ons een eerste stap naar een eenduidige manier van risicogestuurd werken aan verkeersveiligheid. DOK data is goed in staat om lessen te trekken uit onze eerdere ervaringen met het product en komt daarmee tot een landelijk verklaarbaar verkeersveiligheidsmodel. Een model wat tegelijkertijd zijn unieke en voorspellende kracht niet verliest. Daarmee stelt het ook andere gemeenten in staat om op basis van landelijk toegankelijke data al op een proactieve manier risico’s aan te pakken binnen het wegen- en fietsnetwerk. Gemeente Rotterdam is trots dat het sinds 2018 aan de basis staat van deze innovatie en moedigt nieuwe overheden aan om aansluiting te vinden."'
                                    secondary='Remco Smit, coördinator verkeersveiligheid gemeente Rotterdam'
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid> */}

                <Grid item xs={12}>
                    <Paper sx={{ padding: '1rem' }}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src="img/anco.png" width="50px" height="50px" alt="Remco Smit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={"\"Door het vroegtijdig aanpakken van verkeersveiligheidsrisicos zetten wij in op het voorkomen van ongevallen. In de Utrechtse Verkeersveiligheidsopgave en in de Regionale Uitvoeringsagenda Verkeersveiligheid zijn twaalf regionale verkeersveiligheidsrisico's gedefinieerd. Veiligheidsrisico's die we in de komende jaren samen met de wegbeheerders en stakeholders gaan aanpakken. Het Utrechtse Verkeersveiligheidsmodel helpt bij het opsporen, duiden, prioriteren en het monitoren van deze verkeersveiligheidsrisico's. De visualisatie maakt complexe materie toegankelijker.\""}
                                    secondary='Anco Verstraaten, coördinerend beleidsmedewerker verkeersveiligheid provincie Utrecht'
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid>

                {/* <Grid item xs={12}>
                    <Paper sx={{ padding: '1rem' }}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar>
                                        <img src="img/anco.png" width="50px" height="50px" alt="Anco Verstraaten" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary='"QUOTE"'
                                    secondary='Anco Verstraaten, coördinerend beleidsmedewerker verkeersveiligheid provincie Utrecht'
                                />
                            </ListItem>
                        </List>
                    </Paper>
                </Grid> */}

            </Grid>

        </>
    )
}

export default About;

// const clientData = [
//     {
//         img: 'img/logo_gem_rdam.png',
//         title: 'Gemeente Rotterdam',
//         link: 'https://rotterdam.nl',
//     },
//     {
//         img: 'img/logo_prov_utrecht.png',
//         title: 'Provincie Utrecht',
//         link: 'https://provincie-utrecht.nl',
//     },
//     {
//         img: 'img/logo_gem_den_haag.png',
//         title: 'Gemeente Den Haag',
//         link: 'https://denhaag.nl',
//     },
//     {
//         img: 'img/logo_prov_gelderland.png',
//         title: 'Provincie Gelderland',
//         link: 'https://www.gelderland.nl',
//     },
//     {
//         img: 'img/logo_prov_friesland.png',
//         title: 'Provincie Fryslan',
//         link: 'https://fryslan.frl',
//     },
//     {
//         img: 'img/logo_prov_drenthe.png',
//         title: 'Provincie Drenthe',
//         link: 'https://provincie.drenthe.nl',
//     },
// ];
const companyData = [
    {
        img: 'img/logo_dok_data.png',
        title: 'DOK data',
        link: 'https://dokdata.nl',
    },
    {
        img: 'img/logo_arcadis.png',
        title: 'Arcadis',
        link: 'https://www.arcadis.com/nl-nl',
    },
    {
        img: 'img/logo_cyclomedia.png',
        title: 'Cyclomedia',
        link: 'https://cyclomedia.com/nl',
    },
    {
        img: 'img/logo_ellipsis.png',
        title: 'Ellipsis Drive',
        link: 'https://ellipsis-drive.com',
    },
];