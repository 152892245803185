import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import EventIcon from '@mui/icons-material/Event';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import CalendarDialog from "./CalendarDialog";

const Contact = () => {
    const [openCalendarDialog1, setOpenCalendarDialog1] = useState(false);
    return (
        <>
            <Typography variant="h4" color="text.primary" align="center">
                {"Contact"}
            </Typography>
            <div className='fillerSmall' />
            <Typography variant="body1" color="text.primary" align="left">
                Vragen over mogelijkheden, beschikbare risico-indicatoren, samenwerking, invulling SPV of iets anders? Neem gerust contact op.
            </Typography>
            <div className='fillerSmall' />
            <div align="center">
                <Grid container justifyContent="center" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <HomeIcon /><br />
                        DOK data B.V.<br />
                        Weena 70<br />
                        3012 CM Rotterdam<br />
                        Kantoor 9.6<br />
                        KVK: 81939884
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <MailIcon /><br />
                        <Button variant="contained" href="mailto:support@dokdata.nl">support@dokdata.nl</Button>
                        <div className='fillerSmall' />
                        <Button variant="contained" color="warning" startIcon={<EventIcon />} onClick={() => setOpenCalendarDialog1(true)}>Plan een demosessie</Button>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <PhoneAndroidIcon /><br />
                        <Button variant="contained" href="callto:+31642690912">06 42690912</Button>
                    </Grid>
                </Grid>
            </div>
            <CalendarDialog
                open={openCalendarDialog1}
                handleClose={() => setOpenCalendarDialog1(false)}
                iframeSrc='https://koalendar.com/e/demo-ongevalrisiconl?embed=true'
            />
        </>
    )
}

export default Contact